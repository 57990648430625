<template>
  <div class="page margin-left margin-left-large padding-left layout-vertical">
    <div class="layout-horizontal layout-middle layout-inflexible">
      <div class="font-size-extra-large margin-right">
        <back-button @click="handleBackClick"></back-button>
      </div>
      <ys-input placeholder="搜索打卡" prefix-icon="el-icon-search" class="margin-vertical input-search" dark hollow
                v-model="search">
      </ys-input>
      <ys-button lighting class="margin-left-large" @click="handleAddClick">+ 添加打卡</ys-button>
    </div>
    <div class="layout-flexible" style="overflow: hidden;">
      <ys-infinite-scroll-view style="height: 100%;" @loadmore="loadMore">
        <div class="padding-vertical-small">
          <div
              class="list-item layout-horizontal layout-middle padding-horizontal-large padding-vertical"
              v-for="punch in punchList" :key="punch.id"
              @click="clickPunch(punch)"
          >
            <i class="fas fa-calendar-check font-size-medium"></i>
            <div class="layout-flexible font-size-medium margin-left">
              {{ punch.title }}
            </div>
            <div class="layout-inflexible">
              <ys-button round class="btn-delete margin-right-small" type="secondary"
                         @click.stop="clickDelete(punch)">
                <i class="far fa-trash-alt font-color-danger"></i>
              </ys-button>
            </div>
            <i class="fa fa-chevron-right font-color-placeholder"></i>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <delete-dialog :visible.sync="deleteDialogVisible"
                   @confirm="confirmDelete"
                   :message="`您确定要删除打卡：${deletingPunch ? deletingPunch.title : ''}吗？`"
    ></delete-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsButton from "@/components/wedigets/YsButton";
import httpapi from "@/assets/javascript/httpapi";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "PunchList",
  mixins: [httpapi],
  components: {DeleteDialog, YsButton, YsInfiniteScrollView, YsInput, BackButton},
  props: {
    organizationId: Number,
  },
  data() {
    return {

      busyLoadingCode: 0,

      search: null,

      punchList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      currentPunchId: null,

      deletingPunch: null,
      deleteDialogVisible: false,

    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
        this.currentPunchId = null;
        if (this.organizationId) this.loadPunchList();
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('notify:punch_add', this.handlePunchAdd);
  },
  activated() {
    if (this.currentPunchId) {
      this.loadPunch(this.currentPunchId);
      this.currentPunchId = null;
    }
  },
  methods: {
    handleBackClick: function () {
      this.$router.replace({name: 'users'})
    },
    handleAddClick: function () {
      this.$router.push({name: 'punch'});
    },
    clickPunch: function (punch) {
      this.currentPunchId = punch.id;
      this.$router.push({name: 'punch', query: {id: punch.id}});
    },
    clickDelete(punch) {
      this.deletingPunch = punch;
      this.deleteDialogVisible = true;
    },
    confirmDelete() {
      this.removePunch(this.deletingPunch.id);
    },
    handlePunchAdd(punchId) {
      this.currentPunchId = punchId;
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadPunchList: function (pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/punch/list/${this.organizationId}/${pageNum}/${this.pageSize}` + (this.search ? `/${this.search}` : ''),
      })
          .returnWhenCodeMatches()
          .then(res => {
            let punchList = pageNum == 1 ? [] : this.punchList;
            this.$appendAfter(punchList, res.data.list);
            this.punchList = punchList;
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) this.loadPunchList(this.pageNum + 1);
    },
    loadPunch: function (punchId) {
      this.$reqGet({
        path: `/punch/${punchId}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            let punch = res.data;
            this.$appendBefore(this.punchList, [punch]);
          })
          .catch(() => this.$message.error('加载失败'));
    },
    removePunch: function (punchId) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/punch/${punchId}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            for (let n = this.punchList.length - 1; n >= 0; n--) {
              if (this.punchList[n].id === punchId) {
                this.punchList.splice(n, 1);
                break;
              }
            }
            this.$message.success('删除成功');
          })
          .catch(() => this.$message.error('删除失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }

}
</script>

<style scoped>

.page {
  height: 100vh;
}

.list-item .btn-delete {
  visibility: hidden;
}

.list-item:hover .btn-delete {
  visibility: visible;
}

</style>